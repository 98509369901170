<template>
    <q-dialog v-model="visible" persistent>
        <q-card style="width: 800px; max-width: 60vw">
            <q-bar class="bg-primary text-white">
                <q-icon name="account_box" />
                <div>
                    用户授权<small class="q-ml-sm text-size-sm"
                        >{{ UserEntity.UserName }} ({{
                            UserEntity.UserCode
                        }})</small
                    >
                </div>

                <q-space />

                <q-btn dense flat icon="close" v-close-popup>
                    <q-tooltip>关闭</q-tooltip>
                </q-btn>
            </q-bar>
            <q-card-section>
                <q-stepper
                    v-model="step"
                    color="primary"
                    animated
                    :bordered="false"
                    header-nav
                >
                    <q-step
                        :name="1"
                        title="机构授权"
                        icon="location_city"
                        :done="done1"
                    >
                        <div style="height: 380px; overflow-y: auto">
                            <UserHosp />
                        </div>
                        <q-stepper-navigation>
                            <q-btn
                                class="float-right"
                                color="primary"
                                icon="save"
                                @click="onSaveHosp"
                                >保存</q-btn
                            >
                            <q-btn
                                @click="
                                    () => {
                                        done1 = true;
                                        step = 2;
                                    }
                                "
                                class="q-ml-sm"
                                color="primary"
                                label="下一步"
                            />
                        </q-stepper-navigation>
                    </q-step>

                    <q-step
                        :name="2"
                        title="角色授权"
                        icon="account_circle"
                        :done="done2"
                    >
                        <div style="height: 380px; overflow-y: auto">
                            <UserRole />
                        </div>

                        <q-stepper-navigation>
                            <q-btn
                                class="float-right"
                                color="primary"
                                icon="save"
                                @click="onSaveRole"
                                >保存</q-btn
                            >
                            <q-btn
                                class="q-ml-sm"
                                @click="onFinish"
                                color="primary"
                                label="完成"
                            />
                            <q-btn
                                flat
                                @click="step = 1"
                                color="primary"
                                label="上一步"
                                class="q-ml-sm"
                            />
                        </q-stepper-navigation>
                    </q-step>
                </q-stepper>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UserHosp from "./userHosp";
import UserRole from "./userRole";
export default {
    components: {
        UserHosp,
        UserRole,
    },
    computed: {
        ...mapState("SysUser", ["ShowAuth", "UserEntity"]),
        ...mapState("SysRoleUser", ["RoleUserList"]),
        ...mapState("SysHospUser", ["SelectedHospIDs"]),
        visible: {
            get() {
                return this.ShowAuth;
            },
            set(val) {
                this.$store.dispatch("SysUser/actShowAuthDlg", val);
                this.step = 1;
            },
        },
    },
    data() {
        return {
            step: 1,
            done1: false,
            done2: false,
        };
    },
    methods: {
        ...mapActions("SysRoleUser", ["actUpdateRoleListByUserID"]),
        ...mapActions("SysHospUser", ["actUpdateHospUserListByUserID"]),
        onSaveHosp() {
            let hospParm = {
                UserID: this.UserEntity.UserID,
                HospIDs: this.SelectedHospIDs,
            };
            this.actUpdateHospUserListByUserID(hospParm);
        },
        onSaveRole() {
            let roleParm = {
                UserID: this.UserEntity.UserID,
                RoleList: this.RoleUserList,
            };
            this.actUpdateRoleListByUserID(roleParm);
        },
        onFinish() {
            this.visible = false;
        },
    },
};
</script>