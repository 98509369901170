<template>
    <q-table
        dense
        :rows="RoleList"
        :columns="columns"
        row-key="RoleID"
        selection="multiple"
        :bordered="false"
        :pagination="{ rowsPerPage: 0 }"
        class="no-box-shadow"
        separator="cell"
        table-header-class="text-primary font-size-lg"
        v-model:selected="roleUserList"
    />
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState("SysRole", ["RoleList"]),
        ...mapState("SysRoleUser", ["RoleUserList"]),
        ...mapState("SysUser", ["ShowAuth", "UserEntity"]),
        roleUserList: {
            get() {
                return this.RoleUserList;
            },
            set(val) {
                this.$store.commit("SysRoleUser/setUserList", val);
            },
        },
    },
    watch: {
        ShowAuth: {
            handler(val) {
                if (val) {
                    this.actLoadData();
                    this.actLoadRoleList({ UserID: this.UserEntity.UserID });
                }
            },
            immediate: true,
            deep: true,
        },
    },
    data() {
        return {
            columns: [
                {
                    name: "RoleName",
                    label: "请选择授权的角色",
                    align: "left",
                    field: (row) => row.RoleName,
                },
            ],
        };
    },
    methods: {
        ...mapActions("SysRoleUser", [
            "actLoadRoleList",
        ]),
        ...mapActions("SysRole", ["actLoadData"]),
    },
};
</script>